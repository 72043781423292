import { createSlice } from '@reduxjs/toolkit';

export const token = createSlice({
    name: 'token',
    initialState: {
        access_token: '',
        refresh_token: '',
        token_expiration_date: ''
    },
    reducers: {
        setAccessToken: (state, action) => {
            state.access_token = action.payload;
        },
        setRefreshToken: (state, action) => {
            state.refresh_token = action.payload;
        },
        setTokenExpirationDate: (state, action) => {
            state.token_expiration_date = action.payload;
        }
    }
});

export const { setAccessToken, setRefreshToken, setTokenExpirationDate } = token.actions;
