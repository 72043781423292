import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import { address } from './addressSlice';
import { portfolioValue } from './portfolioValueSlice';
import { token } from './tokenSlice';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    address: address.reducer,
    portfolioValue: portfolioValue.reducer,
    token: token.reducer
});

export default reducer;
