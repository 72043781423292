// prettier-ignore
import { useLocalStorage } from 'react-use';
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'axios';

import AuthContext from './AuthContext';

// prettier-ignore
const AuthProvider = ({ children }) => {
    const [credentials, setCredentials] = useLocalStorage("auth.credentials", {});
    const [isRefreshing, setIsRefreshing] = useState(false);

    const isExpired = () => {
        if (Date.now() >= credentials.expiryDate) {
            return true;
        }
        return false;
    };
    // prettier-ignore
    const isLoggedIn = () => {
        if (
            credentials.access_token !== undefined &&
            credentials.expiryDate !== undefined
        ) {
            return !isExpired();
        }
        return false;
    };

    const hasToRefreshToken = !isLoggedIn() && isExpired();

    const updateAuth = React.useCallback((newAuth) => {
        setCredentials(newAuth.credentials);
    }, []);

    const [value, setValue] = React.useState({
        isLoggedIn,
        update: updateAuth,
        values: { credentials },
        isRefreshing,
        hasToRefreshToken,
    });

    React.useEffect(() => {
        setValue({
        isLoggedIn,
        update: updateAuth,
        values: { credentials },
        isRefreshing,
        hasToRefreshToken,
        });
    }, [credentials, isRefreshing]);

    useEffect(() => {
        const attemptTokenRefresh = async () => {
            setIsRefreshing(true);

            const url = `${process.env.REACT_APP_DJANGO_API_URL}o/token/`;
            const data = {
                grant_type: "refresh_token",
                client_id: process.env.REACT_APP_DJANGO_CLIENT_ID,
                refresh_token: credentials.refresh_token,
            };
            const options = {
                method: "POST",
                // headers: { 'content-type': 'application/x-www-form-urlencoded'},
                data: qs.stringify(data),
                url,
            };

            try {
                const response = await axios(options);

                const expiryDate = Date.now() + response.data.expires_in * 1000;

                const newCredentials = {
                    ...response.data,
                    expiryDate,
                };

                updateAuth({ credentials: newCredentials });
            } catch (error) {
                updateAuth({ credentials: {} });
            }

            setIsRefreshing(false);
            };

        if (hasToRefreshToken) {
            attemptTokenRefresh();
        }
    }, []);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
