import { CLIENT_ID } from 'config';
import AuthService from '../services/AuthService';
// prettier-ignore
const authService = new AuthService();

class APISerivce {
    constructor(axios) {
        this.axios = axios;
    }

    postRegistration(email, password, fname, lname) {
        const varParams = new URLSearchParams();
        varParams.set('fname', 'fname');
        varParams.set('lname', 'lname');
        varParams.set('grant_type', 'password');
        varParams.set('client_id', CLIENT_ID);
        varParams.set('username', email);
        varParams.set('password', password);
        varParams.set('email', email);
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        return this.axios.post('/o/signup/', varParams, headers);
    }

    getAccessToken(username, password) {
        const varParams = new URLSearchParams();
        varParams.set('grant_type', 'password');
        varParams.set('client_id', CLIENT_ID);
        varParams.set('username', username);
        varParams.set('password', password);
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        return this.axios.post('/o/token/', varParams, headers);
    }

    fetchUser() {
        return this.axios.get('/user_profile/');
    }

    fetchPortfolioGasSpend() {
        return this.axios.get('/portfolio/gas_spend/');
    }

    getRefreshToken() {
        const varParams = new URLSearchParams();
        varParams.set('grant_type', 'refresh_token');
        varParams.set('client_id', CLIENT_ID);
        varParams.set('refresh_token', authService.getRefreshToken());
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        return this.axios.post('/o/token/', varParams, headers);
    }

    fetchAddresses() {
        return this.axios.get('address_book/');
    }

    fetchTransactions(address) {
        return this.axios.get(`api/v1/eth_transactions/?address=${address}`);
    }

    fetchChartData(address) {
        return this.axios.get(`api/v1/eth_historical_chart/?address=${address}`);
    }

    fetchPortfolioValue(include) {
        return this.axios.get(`portfolio_value/?portfolio=0&include=${include}`);
    }

    portfolioManager(action, address, portfolioNumber) {
        const data = {
            action,
            address,
            portfolio: portfolioNumber
        };
        return this.axios.post('portfolio_manager/', data);
    }

    fetchPortfolioAddresses(portfolioNumber) {
        return this.axios.get(`portfolio_manager/?portfolio=${portfolioNumber}`);
    }

    fetchNftAssets() {
        return this.axios.get('api/nft/assets');
    }

    fetchEthUsdValue() {
        return this.axios.get('eth_to_usd');
    }

    fetchNftPortfolioBreakdown() {
        return this.axios.get('api/nft_collection_breakdown');
    }

    postNiftygatewayOAuthCode(code) {
        const data = {
            authorization_code: code
        };
        return this.axios.post('oauth2/nifty/', data);
    }

    fetchPortfolioAssetAllocation() {
        return this.axios.get('api/portfolio_assets_by_address');
    }

    fetchPortfolioAssetAllocationPieChartData() {
        return this.axios.get('api/pie_data_assets_by_address');
    }

    fetchPortfolioHoldingByAssetClassPieChartData() {
        return this.axios.get('api/pie_data_balance_by_asset_class');
    }

    fetchTimeline() {
        return this.axios.get('transaction_timeline/');
    }

    fetchNftInfoTimeline(tokenId, contractAddress) {
        return this.axios.get(`nft_information/timeline/?contract=${contractAddress}&token_id=${tokenId}`);
    }
}

export default APISerivce;
