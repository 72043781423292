import { useEffect, useState } from 'react';

// react router dom
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../../api/index';

const OAuth = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    useEffect(() => {
        const oAuthCode = searchParams.get('code');
        api.postNiftygatewayOAuthCode(oAuthCode).then((res) => navigate('/dashboard'));
    }, []);
    return <></>;
};

export default OAuth;
