import React from 'react';

// prettier-ignore
const AuthContext = React.createContext({
  accessToken: "",
  expiresIn: 0,
  tokenType: "",
  scope: "",
  refreshToken: "",
  values: {
    credentials: {
      access_token: "",
    },
  },
  isLoggedIn: () => false,
  isRefreshing: false,
  hasToRefreshToken: false,
});

export const useAuthContext = () => React.useContext(AuthContext);

export default AuthContext;
