/* eslint-disable implicit-arrow-linebreak */
// prettier-ignore
import get from 'lodash/get';
import axios from 'axios';
import AuthService from '../services/AuthService';
import { API_URL } from 'config';
import APISerivce from './api';
// prettier-ignore
const authService = new AuthService();
const instance = axios.create({ baseURL: API_URL });

// instance.defaults.headers.post['Content-Type'] = 'multipart/form-data';
// prettier-ignore
// request interceptor
instance.interceptors.request.use(
  (config) => {
    // set access token
    const token = authService.getAccessToken()
    const tokenType = authService.getTokenType()
    if (token && tokenType) {
      /* eslint-disable no-param-reassign */
      // TODO - Store type of token from server, store in local storage and set here
      config.headers.Authorization = `${tokenType} ${token}`
    }
    return config
  },
  (error) => Promise.reject(error),
)
// prettier-ignore
// Add a response interceptor
instance.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response.data,
  (error) => {
    if (error.response) {
      // client received an error response (5xx, 4xx)
      error.message = get(
        error,
        ['response', 'data', 'message'],
        'Unable to parse error response.',
      )
      return Promise.reject(error)
    }
    if (error.request) {
      // client never received a response, or request never left
      error.message = 'Unable to connect to the server.'
      return Promise.reject(error)
    }
    // error while setting up the request - error.message
    return Promise.reject(error)
  },
)

const api = new APISerivce(instance);

export default api;
