import { createSlice } from '@reduxjs/toolkit';

export const address = createSlice({
    name: 'address',
    initialState: {
        addressList: []
    },
    reducers: {
        addAddress: (state, action) => {
            state.addressList = action.payload;
        },
        deleteAddress: (state, action) => {
            const addressListHolder = [];
            state.addressList = addressListHolder.concat(action.payload);
        }
    }
});

export const { addAddress, deleteAddress } = address.actions;
