import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const NFTDashboardDefault = Loadable(lazy(() => import('views/nftDashboard/Default')));
const TimelineDefault = Loadable(lazy(() => import('views/timeline/Default')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/nft',
            element: <NFTDashboardDefault />
        },
        {
            path: '/dashboard/timeline',
            element: <TimelineDefault />
        }
    ]
};

export default MainRoutes;
