import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { useEffect } from 'react';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddresses } from './api/actions/apiActions';
import { addAddress } from './store/addressSlice';

// api
import api from './api';
import UserProvider from './context/UserProvider';
import AuthProvider from 'context/AuthProvider';

import ReactGA from 'react-ga';

const TRACKING_ID = 'G-1SPK2G2F72'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <AuthProvider>
                <UserProvider>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        <NavigationScroll>
                            <Routes />
                        </NavigationScroll>
                    </ThemeProvider>
                </UserProvider>
            </AuthProvider>
        </StyledEngineProvider>
    );
};

export default App;
