import { createSlice } from '@reduxjs/toolkit';
import api from '../api';

export const portfolioValue = createSlice({
    name: 'portfolioValue',
    initialState: {
        totalPortfolioValue: 0.0,
        ethPortfolioValue: 0.0,
        erc20PortfolioValue: 0.0,
        usdPortfolioValue: 0.0
    },
    reducers: {
        updateTotalPortfolioValue: (state, action) => {
            state.totalPortfolioValue = action.payload;
            console.log(action.payload);
        },
        updateEthPortfolioValue: (state, action) => {
            state.ethPortfolioValue = action.payload;
        },
        updateErc20PortfolioValue: (state, action) => {
            state.erc20PortfolioValue = action.payload;
        },
        updateUsdPortfolioValue: (state, action) => {
            state.usdPortfolioValue = action.payload;
        }
    }
});

export const { updateTotalPortfolioValue, updateEthPortfolioValue, updateErc20PortfolioValue, updateUsdPortfolioValue } =
    portfolioValue.actions;
