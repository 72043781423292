import _ from 'lodash';

const CREDENTIALS_KEY = 'auth.credentials';

// prettier-ignore
class AuthService {
// prettier-ignore
  async setCredentials(credentials) {
    this.name = 'none'
    try {
        if (credentials.expires_in) {
            const expiryDate = Date.now() + credentials.expires_in * 1000
            credentials = { ...credentials, expiryDate }
          }
          await localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(credentials))
    } catch (err) {
        // setError
    }
    return credentials
  }

// prettier-ignore
  parseCredentials(credentials) {
    this.name = 'none'
    try {
      return JSON.parse(credentials)
    } catch (e) {
      return null
    }
  }

// prettier-ignore
  getCredentials() {
    const credentials = localStorage.getItem(CREDENTIALS_KEY)
    if (_.isNull(credentials)) {
      return null
    }
    return this.parseCredentials(credentials)
  }

  getAccessToken() {
    const credentials = this.getCredentials()
    if (_.isNull(credentials)) {
      return null
    }
    return credentials.access_token
  }

  getRefreshToken() {
    const credentials = this.getCredentials()
    if (_.isNull(credentials)) {
      return null
    }
    return credentials.refresh_token
  }

  getTokenType() {
    const credentials = this.getCredentials()
    if (_.isNull(credentials)) {
      return null
    }
    return credentials.token_type
  }

  getExpirationDate() {
    const credentials = this.getCredentials()
    if (_.isNull(credentials)) {
      return null
    }
    return credentials.expiry_date
  }

  isTokenValid() {
    const accessToken = this.getAccessToken()
    const expiryDate = this.getExpirationDate()
    if (_.isNull(accessToken) || _.isNull(expiryDate)) {
      return false
    }
    return Date.now() < expiryDate
  }

// prettier-ignore
  clearCredentials() {
    this.name = 'none'
    localStorage.removeItem(CREDENTIALS_KEY)
  }
}

export default AuthService;
