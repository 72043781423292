import React from 'react';

// prettier-ignore
const UserContext = React.createContext({
  fetchUser: () => {},
  loginUser: (username, password) => {},
  logoutUser: () => {},
  registerUser: (email, password, fname, lname) => {},
  user: null,
  error: null,
})

export const useUser = () => React.useContext(UserContext);

export default UserContext;
