// prettier-ignore
import isNil from 'lodash/isNil';
import React, { useState, useEffect, useMemo, useCallback, ReactNode } from 'react';
import AuthService from '../services/AuthService';
import api from '../api';
import { initialState } from '../store/customizationReducer';
import UserContext from './UserContext';

// prettier-ignore
const UserProvider = ({ children }) => {
  const authService = new AuthService()
  // local state
  const [user, setUser] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [error, setError] = useState(null)

  // fetch/refresh user
  const fetchUser = useCallback(async () => {
    setIsFetching(true)
    try {
      const userProfile = await api.fetchUser()
      setUser(userProfile)
      setIsLoggedIn(true)
    } catch (err) {
        console.log(err);
      // setError(err.message)
    } finally {
      setIsFetching(false)
    }
  }, [])

  // login user
  const loginUser = useCallback(async (username, password) => {
    setIsFetching(true)
    try {
      const credentials = await api.getAccessToken(username, password)
      await authService.setCredentials(credentials)
      const userProfile  = await api.fetchUser()
      setUser(userProfile)
      setIsLoggedIn(true)
      setError(null)
      console.log('fetching user', userProfile)
    } catch (err) {
        console.log('fetching error', err)
    } finally {
      setIsFetching(false)
    }
  }, [])

  const registerUser = useCallback(async (email, password) => {
    setIsFetching(true)
    try {
      const credentials = await api.postRegistration(email, password)
      await authService.setCredentials(credentials)
      const userProfile  = await api.fetchUser()
      setUser(userProfile)
      setIsLoggedIn(true)
      setError(null)
      console.log('fetching user', userProfile)
    } catch (err) {
      console.log('fetching error', err)
    } finally {
      setIsFetching(false)
    }
  }, [])

  const logoutUser = useCallback(async () => {
    await authService.clearCredentials()
    setIsLoggedIn(false)
  }, [])

  // credentials
  const isTokenValid = authService.isTokenValid()

  // global state
  const value = useMemo(
    () => ({
      user,
      isLoggedIn,
      isFetching,
      error,
      fetchUser,
      loginUser,
      logoutUser,
      registerUser
    }),
    [user, isLoggedIn, isFetching, error, fetchUser, loginUser, logoutUser, registerUser],
  )

  useEffect(() => {
    if (isTokenValid) {
      fetchUser()
    } 
  }, [])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserProvider;
