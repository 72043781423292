// material-ui
import { Link, Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" target="_blank" underline="hover">
            Donations welcome @ 0x967E031D83D9feeC270A40CbDD667152e0211013
        </Typography>
    </Stack>
);

export default AuthFooter;
