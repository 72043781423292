import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Grid, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

import Header from './Header';
import { gridSpacing } from 'store/constant';

// ==============================|| MINIMAL LAYOUT ||============================== //
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.layoutContent,
    ...{
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `100%`
    }
}));

const LandingLayout = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <AppBar
                        enableColorOnDark
                        position="fixed"
                        color="inherit"
                        elevation={0}
                        sx={{
                            bgcolor: theme.palette.background.default
                        }}
                    >
                        <Toolbar>
                            <Header />
                        </Toolbar>
                    </AppBar>
                </Grid>
            </Grid>
            <Main theme={theme} open={false}>
                <Outlet />
            </Main>
        </>
    );
};

export default LandingLayout;
