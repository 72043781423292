import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import { config } from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const NavigationSection = () => (
    <>
        <ButtonBase disableRipple component={Link} to="/dashboard" sx={{ px: 2.5 }}>
            <Typography sx={{ textAlign: 'center', justifyContent: 'center' }} variant="h5" color="secondary">
                DASHBOARD
            </Typography>
        </ButtonBase>
        <ButtonBase disableRipple component={Link} to="/dashboard/nft" sx={{ px: 2.5 }}>
            <Typography sx={{ textAlign: 'center', justifyContent: 'center' }} variant="h5" color="secondary">
                NFT DASHBOARD
            </Typography>
        </ButtonBase>
        <ButtonBase disabled disableRipple component={Link} to="/portfolio" sx={{ px: 2.5 }}>
            <Typography sx={{ textAlign: 'center', justifyContent: 'center' }} variant="h5" color="grey">
                PORTFOLIO
            </Typography>
        </ButtonBase>
    </>
);

export default NavigationSection;
