import { lazy } from 'react';

// project imports
import LandingLayout from '../layout/LandingLayout';
import Loadable from 'ui-component/Loadable';
import OAuth from 'views/niftyOAuth/Default';

// dashboard routing
const LandingDefault = Loadable(lazy(() => import('views/landing/Default')));
const PortfolioDefault = Loadable(lazy(() => import('views/onboarding/initPortfolio')));

// ==============================|| MAIN ROUTING ||============================== //

const LandingRoutes = {
    path: '/',
    element: <LandingLayout />,
    children: [
        {
            path: '/',
            element: <LandingDefault />
        },
        {
            path: '/portfolio-creation',
            element: <PortfolioDefault />
        },
        {
            path: '/callback',
            element: <OAuth />
        }
    ]
};

export default LandingRoutes;
